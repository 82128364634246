import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Tabs, TabsProps } from "antd";
import { LoaderBox } from "src/components/LoaderBox";
import { t } from "i18next";
import { schedulingControlStore as store } from "./SchedulingControlStore";
import { SchedulingTable } from "./SchedulingTable";
import styles from "./SchedulingControl.module.less";
import { SchedulingHeader } from "./SchedulingHeader";

interface PropsSchedulingControl {
  entityId: number;
  objectServiceId: number;
}

export const SchedulingControl: React.FC<PropsSchedulingControl> = observer(
  ({ entityId, objectServiceId }) => {
    useEffect(() => {
      store.init(entityId, objectServiceId);
    }, [entityId, objectServiceId]);
    return <LoaderBox remoteData={store.data} drawReady={() => <Inner />} />;
  },
);

const Inner: React.FC = observer(() => {
  const { data } = store;
  if (data.status !== "ready") return null;
  const items: TabsProps["items"] = [
    {
      key: "taskList",
      label: t("Task list"),
      children: <SchedulingTable store={store} />,
    },
  ];
  return (
    <>
      <SchedulingHeader store={store} />
      <div className={styles.box}>
        <Tabs items={items} />
      </div>
    </>
  );
});
