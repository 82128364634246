import { t } from "i18next";
import dayjs from "dayjs";

export const dateViewFormat = () => t("DateFormat", "DD.MM.YYYY");
export const dateTimeViewFormat = () => t("DateTimeFormat", "DD.MM.YYYY HH:mm");
export const dateValueFormat = "YYYY-MM-DD";
export const formatDate2ValueFormat = (date: string): string => {
  const md = dayjs(date);
  return md.isValid() ? md.format(dateValueFormat) : "";
};

export const viewDateUtc = (
  rawDate?: string | null,
  format?: string,
): string =>
  rawDate
    ? dayjs
        .utc(rawDate)
        .local()
        .format(format ?? dateViewFormat())
    : "";

export const viewDateTimeUtc = (
  rawDate?: string | null,
  format?: string,
): string => {
  if (!rawDate) return "";
  const utcTime = dayjs.utc(rawDate);
  if (!utcTime.isValid()) return rawDate;
  // Считаем, что rawDate соответствует Лондонскому времени. Поэтому переводим его в локальное.
  return utcTime.local().format(format ?? dateTimeViewFormat());
};
