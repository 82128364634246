// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__tableBox--BIKUm {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__tableHeader--EyJMc {
  display: grid;
  overflow: hidden;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__tableBody--kOWl9 {
  flex: 1;
  overflow: auto;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cellTop--bbBiL {
  border-top: thin solid #E1E3E6;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cellLeft--9V52b {
  border-left: thin solid #E1E3E6;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cell--Djbz_ {
  border-right: thin solid #E1E3E6;
  border-bottom: thin solid #E1E3E6;
  min-width: 60px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cellRow--pER9c {
  color: #343A41;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  overflow: hidden;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__baseSizeCell--IOKiP {
  background-color: #EFF5FF;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cellHd--N61A4 {
  color: #767C84;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cellCentered--YiX3O {
  text-align: center;
  padding-top: 4px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__pad--xpXMc {
  padding: 8px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__fullCentered--ZSwMr {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/MChartTable/MChartTable.module.less"],"names":[],"mappings":"AAEA;EACI,OAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AADJ;AAIA;EACI,aAAA;EACA,gBAAA;AAFJ;AAIA;EACI,OAAA;EACA,cAAA;AAFJ;AAKA;EACI,8BAAA;AAHJ;AAKA;EACI,+BAAA;AAHJ;AAMA;EACI,gCAAA;EACA,iCAAA;EACA,eAAA;AAJJ;AAOA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AALJ;AAOA;EACI,yBAAA;AALJ;AAQA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AANJ;AAQA;EACI,kBAAA;EACA,gBAAA;AANJ;AAQA;EACI,YAAA;AANJ;AASA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAPJ","sourcesContent":["@border: thin solid #E1E3E6;\n\n.tableBox {\n    flex: 1;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n}\n\n.tableHeader {\n    display: grid;\n    overflow: hidden;\n}\n.tableBody {\n    flex: 1;\n    overflow: auto;\n}\n\n.cellTop {\n    border-top: @border;\n}\n.cellLeft {\n    border-left: @border;\n}\n\n.cell {\n    border-right: @border;\n    border-bottom: @border;\n    min-width: 60px;\n}\n\n.cellRow {\n    color: #343A41;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n    overflow: hidden;\n}\n.baseSizeCell {\n    background-color: #EFF5FF;\n}\n\n.cellHd {\n    color: #767C84;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;    \n}\n.cellCentered {\n    text-align: center;\n    padding-top: 4px;\n}\n.pad {\n    padding: 8px;\n}\n\n.fullCentered {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__tableBox--BIKUm`,
	"tableHeader": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__tableHeader--EyJMc`,
	"tableBody": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__tableBody--kOWl9`,
	"cellTop": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cellTop--bbBiL`,
	"cellLeft": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cellLeft--9V52b`,
	"cell": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cell--Djbz_`,
	"cellRow": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cellRow--pER9c`,
	"baseSizeCell": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__baseSizeCell--IOKiP`,
	"cellHd": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cellHd--N61A4`,
	"cellCentered": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__cellCentered--YiX3O`,
	"pad": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__pad--xpXMc`,
	"fullCentered": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MChartTable-module__fullCentered--ZSwMr`
};
export default ___CSS_LOADER_EXPORT___;
