import { z } from "zod";

export const zSchedSettingsTask = z.object({
  templateObjectId: z.number().nullable(),
  nameAttributeId: z.number().nullable(),
  durationAttributeId: z.number().nullable(),
  groupAttributeId: z.number().nullable(),
  // Привязки
  relationObjectId: z.number().nullable(),
  previousAttributeId: z.number().nullable(),
  relationTypeAttributeId: z.number().nullable(),
  deltaDaysAttributeId: z.number().nullable(),
  // Сроки
  deadlineObjectId: z.number().nullable(),
  plannedStartDateAttributeId: z.number().nullable(),
  plannedEndDateAttributeId: z.number().nullable(),
  settlementStartDateAttributeId: z.number().nullable(),
  settlementEndDateAttributeId: z.number().nullable(),
  actualStartDateAttributeId: z.number().nullable(),
  actualEndDateAttributeId: z.number().nullable(),
});
export type ZSchedSettingsTask = z.infer<typeof zSchedSettingsTask>;

export const zSchedSettingsTaskTemplate = zSchedSettingsTask
  .pick({
    templateObjectId: true,
    nameAttributeId: true,
    durationAttributeId: true,
    groupAttributeId: true,
    relationObjectId: true,
    previousAttributeId: true,
    relationTypeAttributeId: true,
    deltaDaysAttributeId: true,
  })
  .extend({
    showByCondition: z.boolean().nullable().optional(),
  });
export type ZSchedSettingsTaskTemplate = z.infer<
  typeof zSchedSettingsTaskTemplate
>;

export const zSchedSettingsGroupTemplate = zSchedSettingsTask
  .pick({
    templateObjectId: true,
    nameAttributeId: true,
  })
  .extend({
    relationPlanTemplateAttributeId: z.number().nullable(),
    taskListAttributeId: z.number().nullable(),
  });
export type ZSchedSettingsGroupTemplate = z.infer<
  typeof zSchedSettingsGroupTemplate
>;

export const zSchedSettingsPlanTemplate = zSchedSettingsTask
  .pick({
    templateObjectId: true,
    nameAttributeId: true,
  })
  .extend({
    groupTaskListAttributeId: z.number().nullable(),
  });
export type ZSchedSettingsPlanTemplate = z.infer<
  typeof zSchedSettingsPlanTemplate
>;

export const zSchedulingSettings = z.object({
  taskSettings: zSchedSettingsTask.nullable(),
  taskTemplateSettings: zSchedSettingsTaskTemplate.nullable(),
  groupSettings: zSchedSettingsGroupTemplate.nullable(),
  planTemplateSettings: zSchedSettingsPlanTemplate.nullable(),
});
export type ZSchedulingSettings = z.infer<typeof zSchedulingSettings>;

const zSchedRowType = z.enum(["PLAN", "GROUP", "TASK"]);
type ZSchedRowType = z.infer<typeof zSchedRowType>;

export interface ZSchedulingRow {
  id: number;
  type: ZSchedRowType;
  name: string;
  state: string;
  duration: number;
  plannedStartDate: string;
  plannedEndDate: string;
  settlementStartDate: string | null;
  settlementEndDate: string | null;
  actualStartDate: string | null;
  actualEndDate: string | null;
  children?: ZSchedulingRow[] | null;
}

export const zSchedulingRow: z.ZodType<ZSchedulingRow> = z.object({
  id: z.number(),
  type: zSchedRowType,
  name: z.string(),
  state: z.string(),
  duration: z.number(),
  plannedStartDate: z.string(),
  plannedEndDate: z.string(),
  settlementStartDate: z.string().nullable(),
  settlementEndDate: z.string().nullable(),
  actualStartDate: z.string().nullable(),
  actualEndDate: z.string().nullable(),
  children: z.lazy(() => zSchedulingRow.array().optional().nullable()),
});

export const zColumnSettings = z.object({
  hiddenColumns: z.string().array().optional(),
  columnWidths: z.record(z.string(), z.number().or(z.string())).optional(),
});
export type ZColumnSettings = z.infer<typeof zColumnSettings>;
