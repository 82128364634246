import { getEditorInfo, ZAttrComponentEditor } from "src/common/attrEdit";
import { ZAttribute } from "src/types/ZAttribute";
import { getComponentEditor } from "src/pages/EntityCardPage/blockBuilder/createItem2";

export const composeLabelsWithEditConfig = (
  attr: ZAttribute,
  values: string[],
  typesMap: Record<number, string>,
): string[] => composeLabel(attr, values, typesMap);

type ComposeFn = (
  values: string[],
  component: ZAttrComponentEditor,
) => string[];

const pipeTransforms = (
  values: string[],
  component: ZAttrComponentEditor,
  fns: ComposeFn[],
) => fns.reduce((acc, curr) => curr(acc, component), values);

const joinAddons: ComposeFn = (
  values: string[],
  component: ZAttrComponentEditor,
) => {
  if (
    !(
      component?.editor === "InputNumber" ||
      component?.editor === "Formula" ||
      component?.editor === "Input" ||
      component?.editor === "MaskedInput"
    )
  )
    return values;
  const { addonAfter, addonBefore } = component;
  return values?.map((v) => {
    let newV = v;
    newV = addonAfter ? `${newV} ${addonAfter}` : newV;
    newV = addonBefore ? `${addonBefore} ${newV}` : newV;
    return newV;
  });
};

const alignNumber: ComposeFn = (
  values: string[],
  component: ZAttrComponentEditor,
) => {
  if (!(component?.editor === "InputNumber" || component?.editor === "Formula"))
    return values;
  const { precision } = component;
  return values?.map((v) => {
    const vNum = Number(v);
    const validPerc = precision !== undefined && precision !== null;
    const canAlign = validPerc && !Number.isNaN(vNum);
    return canAlign ? vNum.toFixed(precision) : v;
  });
};

const composeLabel = (
  attr: ZAttribute,
  values: string[],
  typesMap: Record<number, string>,
): string[] => {
  const editorInfo = getEditorInfo(attr.viewStyles);
  const component = getComponentEditor(editorInfo, typesMap, attr);
  if (!component) return values;
  switch (component.editor) {
    case "InputNumber":
      return pipeTransforms(values, component, [alignNumber, joinAddons]);
    case "Formula":
      return pipeTransforms(values, component, [alignNumber, joinAddons]);
    case "Input":
      return pipeTransforms(values, component, [joinAddons]);
    case "MaskedInput":
      return pipeTransforms(values, component, [joinAddons]);
    default:
      return values;
  }
};
