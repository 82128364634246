import React, { useEffect, useMemo, useState } from "react";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { Button, Flex, notification, Spin, Tree } from "antd";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import { TableStore } from "src/components/tables/TableStore";
import { onError } from "src/common/onError";
import { CopyOutlined } from "@ant-design/icons";
import { EntityCopyButtonStore } from "./EntityCopyButtonStore";
import styles from "./EntityCopyButton.module.less";
import { ZEntityRow, ZEntityFilters } from "../types";
import { EntCopyTreeNode } from "./types";

interface PropsEntityCopyButton {
  tableStore: TableStore<ZEntityRow, ZEntityFilters>;
  objectId: number;
  onLoad?: (flag: boolean) => void;
}

export const EntityCopyButton: React.FC<PropsEntityCopyButton> = observer(
  ({ tableStore, objectId, onLoad }) => {
    const store = useMemo(
      () => new EntityCopyButtonStore(objectId, tableStore),
      [],
    );
    const [modalOpen, setModalOpen] = useState(false);
    const [wait, setWait] = useState(false);

    const {
      defaultChecked,
      checkedKeys,
      onCheck,
      treeData,
      expandedKeys,
      defaultExpanded,
      onExpand,
    } = store;

    const handleCopy = () => {
      setWait(true);
      store
        .copyEntityWithBSs()
        .then(() => {
          notification.success({ message: "Entity copied successfully" });
        })
        .finally(() => {
          setModalOpen(false);
          setWait(false);
        })
        .catch(onError);
    };

    useEffect(() => {
      if (modalOpen) {
        store.setCheckedItems(defaultChecked ?? []);
        store.setExpanded(defaultExpanded ?? []);
      }
    }, [modalOpen]);

    const onClick = () => {
      onLoad?.(true);
      store
        .init()
        .then((res) => {
          if (res === null) return;
          if (res.childAttributes?.length) {
            setModalOpen(true);
          } else {
            handleCopy();
          }
        })
        .finally(() => {
          onLoad?.(false);
        })
        .catch(onError);
    };

    return (
      <>
        <Button
          onClick={onClick}
          icon={<CopyOutlined className={styles.icon} />}
          disabled={tableStore.selected.length !== 1}
          className={styles.button}
        >
          {t("Copying an instance")}
        </Button>
        <ModalVertFixed
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{ disabled: wait }}
          onOk={handleCopy}
          okText={t("Copy")}
          title={t("Copying an instance")}
        >
          <Spin spinning={wait}>
            <Flex vertical gap={8}>
              <div className={styles.subTitle}>
                {t("Select dependent objects to copy")}
              </div>
              <Tree<EntCopyTreeNode>
                treeData={treeData}
                checkable
                expandedKeys={expandedKeys}
                onExpand={onExpand}
                selectable={false}
                checkedKeys={checkedKeys}
                checkStrictly
                onCheck={onCheck}
              />
            </Flex>
          </Spin>
        </ModalVertFixed>
      </>
    );
  },
);
