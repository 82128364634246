import * as React from "react";
import { Form, Input } from "antd";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { ScrollableTable } from "src/components/ScrollableTable";
import { TableStore } from "src/components/tables/TableStore";
import { AColumn } from "src/components/tables/AsyncTable";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  FilterFieldsDict,
  filterItem,
} from "src/components/tables/FiltersForm";
import { NewBomData } from "../Bom2ControlStore";
import styles from "./NewBomModal.module.less";
import { ZBom2Supplier } from "../../Bom2Types";
import { loadBom2Suppliers } from "../../apiBom2";

interface PropsNewBomModal {
  open: boolean;
  close: () => void;
  onSuccess: (values: NewBomData) => void;
}

export const NewBomModal: React.FC<PropsNewBomModal> = observer((props) => {
  const { open, close, onSuccess } = props;
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (open) form.resetFields();
  }, [open]);
  return (
    <ModalVertFixed
      height="82vh"
      open={open}
      onCancel={close}
      okText={t("Add")}
      okButtonProps={{ htmlType: "submit", icon: <PlusCircleOutlined /> }}
      modalRender={(content) => (
        <Form
          className={styles.form}
          form={form}
          layout="vertical"
          onFinish={(values) => {
            onSuccess(values);
            close();
          }}
        >
          {content}
        </Form>
      )}
    >
      <div className={styles.content}>
        <Form.Item
          name="name"
          label={t("Heading")}
          rules={[{ required: true }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="supplier"
          label={t("Supplier")}
          className={styles.tableItem}
        >
          <SupplierSelect />
        </Form.Item>
        <div className={styles.footer} />
      </div>
    </ModalVertFixed>
  );
});

type FilterSupplier = {
  query?: string;
};

interface PropsSupplierSelect {
  value?: ZBom2Supplier;
  onChange?: (newValue?: ZBom2Supplier) => void;
}
const SupplierSelect: React.FC<PropsSupplierSelect> = observer((props) => {
  const { value, onChange } = props;
  const [tableStore] = React.useState(
    new TableStore<ZBom2Supplier, FilterSupplier>({
      rowKey: "id",
      fnLoad: async ({ page, pageSize, filters }) => {
        const { content, totalElements } = await loadBom2Suppliers(
          page,
          pageSize,
          filters?.query,
        );
        return { rows: content, totalItems: totalElements };
      },
      selectionSettings: {
        keepSelected: true,
        selectionType: "radio",
      },
    }),
  );
  React.useEffect(() => {
    if (value) {
      tableStore.setSelected([value]);
    } else {
      tableStore.setSelected([]);
    }
  }, [value]);
  React.useEffect(() => {
    onChange?.(tableStore.selected[0]);
  }, [JSON.stringify(tableStore.selected[0])]);
  const onSelect = (row: ZBom2Supplier) => {
    tableStore.setSelected([row]);
  };
  const columns: AColumn<ZBom2Supplier>[] = React.useMemo(
    () => [
      { key: "name", title: t("Heading"), dataIndex: "name" },
      { key: "country", title: t("Country"), dataIndex: "country" },
    ],
    [],
  );
  const filter: FilterFieldsDict<FilterSupplier> = {
    query: filterItem(
      Input.Search,
      { allowClear: true, placeholder: t("Search") },
      { style: { width: "100%" } },
    ),
  };
  return (
    <ScrollableTable
      store={tableStore}
      columns={columns}
      onRowClick={onSelect}
      filterItems={filter}
    />
  );
});
