import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import { DrawTab } from "./DrawTab";
import { ZSchedSettingsGroupTemplate } from "../../SchedulingTypes";
import { DrawSection } from "./sections/DrawSection";

export const GroupTemplateFields: React.FC = () => (
  <DrawTab>
    <DrawSection<ZSchedSettingsGroupTemplate>
      tabKey="groupSettings"
      objKey="templateObjectId"
      objType="taskGroupTemplate"
      objLabel="Объект для формирования шаблона группы задач"
      title="Список обязательных позиций"
      attrs={[
        {
          attrKey: "nameAttributeId",
          attrLabel: "Укажите атрибут названия группы задач",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: "relationPlanTemplateAttributeId",
          attrLabel: "Укажите атрибут принадлежности к шаблону плана",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: "taskListAttributeId",
          attrLabel: "Укажите атрибут списка задач, включенных в группу задач",
          attrType: AttrTypeName.childEntities,
        },
      ]}
    />
  </DrawTab>
);
