// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingHeader-SchedTemplateTools-SchedGroupCreator-SchedGroupCreator-module__drawer--c9__G .ant-drawer-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 16px 24px;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingHeader-SchedTemplateTools-SchedGroupCreator-SchedGroupCreator-module__drawer--c9__G .ant-drawer-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsScheduling/SchedulingControl/SchedulingHeader/SchedTemplateTools/SchedGroupCreator/SchedGroupCreator.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AALA;EASI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;AADJ","sourcesContent":[".drawer {\n  :global(.ant-drawer-footer) {\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    padding: 16px 24px;\n  }\n\n  :global(.ant-drawer-body) {\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n    height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingHeader-SchedTemplateTools-SchedGroupCreator-SchedGroupCreator-module__drawer--c9__G`
};
export default ___CSS_LOADER_EXPORT___;
