import { rest } from "src/common/rest";
import { apiPlanning } from "src/common/apiUrl";
import { zEntity, ZEntity } from "src/types/ZEntity";
import {
  ZSchedulingRow,
  zSchedulingRow,
  zSchedulingSettings,
  ZSchedulingSettings,
} from "./SchedulingTypes";

export const loadSchedulingSettings = async (
  id: number,
): Promise<ZSchedulingSettings> => {
  const resp = await rest.get(apiPlanning(`/settings/${id}`));
  return zSchedulingSettings.parse(resp.data);
};

export const saveSchedulingSettings = async (
  id: number,
  settings: ZSchedulingSettings,
) => {
  await rest.post(apiPlanning(`/settings/${id}`), settings);
};

export const loadSchedulingData = async (
  entityId: number,
): Promise<ZSchedulingRow> => {
  const resp = await rest.get(apiPlanning(`/plan/${entityId}`));
  return zSchedulingRow.parse(resp.data);
};

export const createTaskGroup = async (entityId: number, templateId: number) => {
  await rest.post(
    apiPlanning(`/plan/${entityId}/createGroupFromTemplate/${templateId}`),
  );
};

export const createTask = async (
  entityId: number,
  task: ZEntity,
): Promise<ZEntity> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...other } = task;
  const result = await rest.post(apiPlanning(`/plan/${entityId}/tasks`), other);
  return zEntity.parse(result);
};

export const deleteTasks = async (entityId: number, taskIds: number[]) => {
  await rest.delete(apiPlanning(`/plan/${entityId}/tasks`), { data: taskIds });
};
