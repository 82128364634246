import React from "react";
import { Spin, Tabs, TabsProps } from "antd";
import { observer } from "mobx-react-lite";
import { schedulingSettingsStore } from "./SchedulingSettingsStore";
import {
  GroupTemplateFields,
  PlanTemplateFields,
  TaskFields,
  TaskTemplateFields,
} from "./tabs";
import styles from "./SchedulingSettings.module.less";

interface PropsSchedulingSettings {
  typesMap: Record<number, string>;
}

export const SchedulingSettings: React.FC<PropsSchedulingSettings> = observer(
  ({ typesMap }) => {
    const { loading } = schedulingSettingsStore;
    React.useEffect(() => {
      schedulingSettingsStore.init(typesMap);
    }, []);

    const items: TabsProps["items"] = [
      {
        key: "task",
        label: "Задача",
        children: <TaskFields />,
        forceRender: true,
      },
      {
        key: "taskTemplate",
        label: "Шаблон задачи",
        children: <TaskTemplateFields />,
        forceRender: true,
      },
      {
        key: "taskGroupTemplate",
        label: "Шаблон группы задач",
        children: <GroupTemplateFields />,
        forceRender: true,
      },
      {
        key: "planTemplate",
        label: "Шаблон плана",
        children: <PlanTemplateFields />,
        forceRender: true,
      },
    ];

    return (
      <Spin spinning={loading}>
        <Tabs className={styles.tabs} items={items} />
      </Spin>
    );
  },
);
