// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorCell-module__box--Y6yht {
  display: flex;
  flex-direction: column;
  border: none;
  cursor: pointer;
  height: 100%;
  background: none;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Position/Bom2Table/BomColorCell/BomColorCell.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;AACJ","sourcesContent":[".box {\n    display: flex;\n    flex-direction: column;\n    border: none;\n    cursor: pointer;\n    height: 100%;\n    background: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorCell-module__box--Y6yht`
};
export default ___CSS_LOADER_EXPORT___;
