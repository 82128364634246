import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { ZAttrViewInfo } from "src/common/attrView";
import { ZAttribute } from "src/types/ZAttribute";
import { TableProps } from "antd";
import { EntityCardStore } from "src/pages/EntityCardPage/EntityCardStore";
import { t } from "i18next";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { onError } from "src/common/onError";
import {
  createTask,
  createTaskGroup,
  deleteTasks,
  loadSchedulingData,
  loadSchedulingSettings,
} from "../apiScheduling";
import { buildColumns } from "./SchedulingTable/buildColumns";
import {
  ZColumnSettings,
  zColumnSettings,
  ZSchedulingRow,
  ZSchedulingSettings,
} from "../SchedulingTypes";
import { transformSchedData } from "./transformSchedData";
import { searchTasks } from "./SchedulingTable/SchedTableToolbar/TaskSearch/searchTasks";

export type AttrColumnDef = {
  attr: ZAttribute;
  viewInfo: ZAttrViewInfo | undefined;
};

export type TaskEditor = {
  cardStore: EntityCardStore;
  title: string;
};

const buildColumnSettingsKey = (entityId: number) =>
  `schedulingSettings-${entityId}`;

export const schedulingControlStore = makeAutoObservable({
  data: { status: "none" } as RemoteData<ZSchedulingRow[]>,
  setData(data: RemoteData<ZSchedulingRow[]>) {
    this.data = data;
  },

  settings: null as ZSchedulingSettings | null,
  setSettings(settings: ZSchedulingSettings | null) {
    this.settings = settings;
  },

  entityId: null as number | null,
  setEntityId(id: number) {
    this.entityId = id;
  },

  objectServiceId: null as number | null,
  setObjectServiceId(id: number) {
    this.objectServiceId = id;
  },

  async init(entityId: number, objectServiceId: number) {
    try {
      this.setEntityId(entityId);
      this.setObjectServiceId(objectServiceId);
      this.hiddenColumns.clear();
      this.setSelectedRows([]);
      this.setSettings(null);
      this.setSearchQuery("");
      this.setGroupCreateStore(null);
      this.setColumnSettingsKey(buildColumnSettingsKey(entityId));
      this.loadColumnSettings();
      await this.loadData();
      const groupTemplateId = this.settings?.groupSettings?.templateObjectId;
      if (!groupTemplateId) return;
      this.setGroupCreateStore(
        new EntityFiltersPageStore({
          actions: new Set([]),
          objectId: groupTemplateId,
          selectionSettings: { selectionType: "radio" },
        }),
      );
    } catch (error) {
      onError(error);
    }
  },

  async loadData() {
    const { entityId, settings, objectServiceId } = this;
    if (!entityId || !objectServiceId) return;
    try {
      this.setData({ status: "wait" });
      if (!settings) {
        const newSettings = await loadSchedulingSettings(objectServiceId);
        if (!newSettings.taskSettings)
          throw Error(t("The service has not been configured"));
        this.setSettings(newSettings);
      }
      const data = await loadSchedulingData(entityId);
      this.setData({ status: "ready", result: transformSchedData(data) });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  },

  get projectInfo(): ZSchedulingRow | null {
    if (this.data.status !== "ready") return null;
    return this.data.result.find(({ type }) => type === "PLAN") ?? null;
  },

  operationLoading: false,
  setOperationLoading(flag: boolean) {
    this.operationLoading = flag;
  },

  // table

  get columns(): TableProps["columns"] {
    return buildColumns(this.dataSource);
  },

  get visibleColumns(): TableProps["columns"] {
    return this.columns?.filter(
      ({ key }) => !this.hiddenColumns.has(String(key)),
    );
  },

  hiddenColumns: new Set<string>(),
  toggleColumnVisibility(key: string) {
    const { hiddenColumns } = this;
    if (hiddenColumns.has(key)) {
      hiddenColumns.delete(key);
    } else {
      hiddenColumns.add(key);
    }
    this.saveColumnSettings();
  },

  get dataSource(): ZSchedulingRow[] {
    const { data, searchQuery } = this;
    if (data.status === "ready") {
      return searchQuery ? searchTasks(data.result, searchQuery) : data.result;
    }
    return [];
  },

  selectedRows: [] as number[],
  setSelectedRows(rowKeys: number[]) {
    this.selectedRows = rowKeys;
  },

  searchQuery: "",
  setSearchQuery(query: string) {
    this.searchQuery = query;
  },

  columnWidths: {} as Record<string, number | string>,
  setColumnWidth(key: string, width: number | string) {
    this.columnWidths[key] = width;
    this.saveColumnSettings();
  },

  columnSettingsKey: null as string | null,
  setColumnSettingsKey(key: string | null) {
    this.columnSettingsKey = key;
  },

  loadColumnSettings() {
    const { columnSettingsKey } = this;
    if (!columnSettingsKey) return;
    try {
      const settings = localStorage.getItem(columnSettingsKey);
      if (!settings) return;
      const json = JSON.parse(settings);
      const { hiddenColumns, columnWidths } = zColumnSettings.parse(json);
      hiddenColumns?.forEach((col) => this.toggleColumnVisibility(col));
      Object.entries(columnWidths ?? {}).forEach(([key, width]) =>
        this.setColumnWidth(key, width),
      );
    } catch (error) {
      onError(error);
    }
  },

  saveColumnSettings() {
    const { columnSettingsKey } = this;
    if (!columnSettingsKey) return;
    try {
      const settings: ZColumnSettings = {
        hiddenColumns: Array.from(this.hiddenColumns),
        columnWidths: this.columnWidths,
      };
      localStorage.setItem(columnSettingsKey, JSON.stringify(settings));
    } catch (error) {
      onError(error);
    }
  },

  // edit/create

  editor: null as TaskEditor | null,
  setEditor(ed: TaskEditor | null) {
    this.editor = ed;
  },

  openEditor(row: ZSchedulingRow) {
    const { id, type } = row;
    if (type !== "TASK") return;
    const cardStore = new EntityCardStore();
    cardStore.init(String(id));
    this.setEditor({ cardStore, title: row.name });
  },

  closeEditor() {
    this.setEditor(null);
  },

  openCreation(taskObjId: number) {
    const { entityId } = this;
    if (!entityId) return;
    const cardStore = new EntityCardStore((ent) => createTask(entityId, ent));
    this.setEditor({ cardStore, title: t("New task") });
    cardStore.initNew(taskObjId);
  },

  onUpdate() {
    this.closeEditor();
    this.loadData();
  },

  // group-template-creation

  openGroupCreate: false,
  setOpenGroupCreate(flag: boolean) {
    this.openGroupCreate = flag;
  },

  groupCreateStore: null as EntityFiltersPageStore | null,
  setGroupCreateStore(store: EntityFiltersPageStore | null) {
    this.groupCreateStore = store;
  },

  async onGroupCreate() {
    const { groupCreateStore, entityId } = this;
    const templateEntityId = groupCreateStore?.tableStore?.selected[0]?.id;
    if (!templateEntityId || !entityId) return;
    try {
      this.setOperationLoading(true);
      await createTaskGroup(entityId, templateEntityId);
      this.setOpenGroupCreate(false);
      this.loadData();
    } catch (error) {
      onError(error);
    } finally {
      this.setOperationLoading(false);
    }
  },

  // delete

  openDelete: false,
  setOpenDelete(flag: boolean) {
    this.openDelete = flag;
  },

  async doDelete() {
    const { selectedRows, entityId } = this;
    if (!entityId) return;
    try {
      this.setOperationLoading(true);
      await deleteTasks(entityId, selectedRows);
      this.setOpenDelete(false);
      this.loadData();
    } catch (error) {
      onError(error);
    } finally {
      this.setOperationLoading(false);
    }
  },
});

export type SchedulingControlStore = typeof schedulingControlStore;
