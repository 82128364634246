import React from "react";
import { Form, Switch } from "antd";
import { DrawTab } from "./DrawTab";
import { ZSchedSettingsTaskTemplate } from "../../SchedulingTypes";
import { TaskRequiredSection, TaskTiesSection } from "./sections";

const fkey = (name: keyof ZSchedSettingsTaskTemplate) => name;

export const TaskTemplateFields: React.FC = () => (
  <DrawTab>
    <Form.Item
      name={["taskTemplateSettings", fkey("showByCondition")]}
      label="Отображать шаблон в зависимости от состояния объекта"
    >
      <Switch />
    </Form.Item>
    <TaskRequiredSection objType="taskTemplate" tabKey="taskTemplateSettings" />
    <TaskTiesSection objType="taskTemplateTies" tabKey="taskTemplateSettings" />
  </DrawTab>
);
