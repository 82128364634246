// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectModal-PointSelectModal-module__tools--WJpy6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/PointSelectModal/PointSelectModal.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;EACA,SAAA;AACF","sourcesContent":[".tools {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  width: 100%;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tools": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectModal-PointSelectModal-module__tools--WJpy6`
};
export default ___CSS_LOADER_EXPORT___;
