import * as React from "react";
import { observer } from "mobx-react-lite";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { Input, Select } from "antd";
import { TableStore } from "src/components/tables/TableStore";
import { ScrollableTable } from "src/components/ScrollableTable";
import { AColumn } from "src/components/tables/AsyncTable";
import { debounce, DebounceCounter } from "src/common/debounce";
import { ZEntityFilters } from "src/pages/EntityFiltersPage/EntityList/types";
import { SearchOutlined } from "@ant-design/icons";
import { idNameFieldNames, ZIdName } from "src/types/ZIdName";
import { t } from "i18next";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { PointOpt } from "../MChartTable/PointDict";
import styles from "./PointSelectModal.module.less";

interface PropsPointSelectModal {
  title: string;
  tableStore: TableStore<PointOpt, ZEntityFilters> | null;
  categoryList: ZIdName[];
  success: (rows: PointOpt[]) => void;
  close: () => void;
}

const qeryCounter: DebounceCounter = {};

export const PointSelectModal: React.FC<PropsPointSelectModal> = observer(
  (props) => {
    const { title, tableStore, categoryList, success, close } = props;
    const [query, setQuery] = React.useState("");
    const [category, setCategory] = React.useState<number | null>(null);

    React.useEffect(() => {
      setQuery("");
      setCategory(null);
    }, [tableStore]);
    return (
      <ModalVertFixed
        title={title}
        height="80vh"
        open={!!tableStore}
        onCancel={close}
        width="700px"
        centered
        onOk={() => {
          success(tableStore?.selected ?? []);
          close();
        }}
      >
        {tableStore && (
          <ScrollableTable
            store={tableStore}
            columns={columns}
            onRowClick={(row) => {
              selectOnRowClick(row, tableStore);
            }}
            tools={
              <div className={styles.tools}>
                <Input
                  placeholder={t("Search by name or description")}
                  value={query}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setQuery(value);
                    debounce(qeryCounter, 600, () => {
                      tableStore.setFilters({
                        ...tableStore.filters,
                        query: value,
                      });
                    });
                  }}
                  suffix={<SearchOutlined />}
                  allowClear
                />
                <Select
                  placeholder={t("Category")}
                  value={category}
                  options={categoryList}
                  fieldNames={idNameFieldNames}
                  onChange={(value) => {
                    setCategory(value);
                    tableStore.setFilters({
                      ...tableStore.filters,
                      dictionaryValueIds: value ? [[value]] : [],
                    });
                  }}
                  disabled={tableStore.loading}
                  allowClear
                />
              </div>
            }
          />
        )}
      </ModalVertFixed>
    );
  },
);

const columns: AColumn<PointOpt>[] = [
  { key: "label", dataIndex: "label", title: t("Point name") },
  { key: "desc", dataIndex: "desc", title: t("Description") },
  { key: "category", dataIndex: "category", title: t("Category") },
];
