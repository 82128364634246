import { Form, Input } from "antd";
import * as React from "react";
import { onError } from "src/common/onError";
import { ImageCarousel } from "src/components/ImageCarousel";
import { ifDef } from "src/common/ifDef";
import { TableStore } from "src/components/tables/TableStore";
import { observer } from "mobx-react-lite";
import { TableFacade } from "src/components/tables/TableFacade";
import { AColumn } from "src/components/tables/AsyncTable";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { FilterBomColors, loadColors, ZBomColorRow } from "./apiBomColor";
import styles from "./BomColorModal.module.less";
import { EdBomColorModel } from "../../ZBom2Position";

export type BomColorInfo = {
  colorManual: string | null;
  colorReference: string[];
  comment: string | null;
  color: ZBomColorRow | null;
};

interface PropsBomColorModal {
  open: boolean;
  close(): void;
  initialData: EdBomColorModel;
  save(data: BomColorInfo): Promise<void>;
}

export const BomColorModal: React.FC<PropsBomColorModal> = (props) => {
  const { open, close, initialData, save } = props;
  const [form] = Form.useForm<BomColorInfo>();
  const [saving, setSaving] = React.useState(false);
  const [tableStore, setTableStore] = React.useState<TableStore<
    ZBomColorRow,
    FilterBomColors
  > | null>(null);
  React.useEffect(() => {
    if (open) {
      form.resetFields();
      const { bomPositionColorModel: cm } = initialData;
      form.setFieldsValue({
        colorManual: cm.colorManual.value,
        colorReference: cm.colorReference.value
          ? [cm.colorReference.value]
          : [],
        comment: cm.comment.value,
      });
      setTableStore(
        new TableStore({
          rowKey: "id",
          fnLoad: async ({ page, pageSize }) => {
            const { content, totalElements } = await loadColors(page, pageSize);
            return { rows: content, totalItems: totalElements };
          },
          selectionSettings: {
            selectionType: "radio",
          },
        }),
      );
    }
  }, [open]);
  const onSubmit = (values: BomColorInfo) => {
    setSaving(true);
    save(values)
      .then(close)
      .finally(() => setSaving(false))
      .catch(onError);
  };
  return (
    <ModalVertFixed
      height="85vh"
      width={800}
      centered
      open={open}
      onCancel={close}
      title={initialData.name}
      okButtonProps={{ htmlType: "submit", loading: saving }}
      //   eslint-disable-next-line react/no-unstable-nested-components
      modalRender={(content) => (
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          className={styles.form}
        >
          {content}
        </Form>
      )}
    >
      <div className={styles.formContent}>
        <div className={styles.firstPart}>
          <div className={styles.firstLeft}>
            <Form.Item name="colorManual" label="Color Manual">
              <Input />
            </Form.Item>
            <Form.Item name="comment" label="comment">
              <Input />
            </Form.Item>
          </div>
          <div className={styles.firstRight}>
            <Form.Item name="colorReference" label="Color Reference">
              <ImageCarousel multiple={false} />
            </Form.Item>
          </div>
        </div>
        <div>
          {!!tableStore && (
            <Form.Item name="color" label="Color">
              <ColorSelect tableStore={tableStore} />
            </Form.Item>
          )}
        </div>
      </div>
    </ModalVertFixed>
  );
};

interface PropsColorSelect {
  value?: ZBomColorRow | null;
  onChange?(newValue?: ZBomColorRow | null): void;
  tableStore: TableStore<ZBomColorRow, FilterBomColors>;
}
const ColorSelect: React.FC<PropsColorSelect> = observer((props) => {
  const { value, onChange, tableStore } = props;
  React.useEffect(() => {
    tableStore.safeSelect(ifDef(value, (v) => [v]) ?? []);
  }, [value]);
  React.useEffect(() => {
    onChange?.(tableStore.selected[0]);
  }, [tableStore.selected]);
  const columns = React.useMemo(
    () =>
      [
        { key: "id", title: "id", dataIndex: "id", width: 46 },
        { key: "name", title: "Name", dataIndex: "name" },
        { key: "article", title: "Article", dataIndex: "article" },
        {
          key: "image",
          title: "Image",
          render: (_, { image }) =>
            image ? (
              <img
                src={imgSrc(image)}
                alt=""
                style={{ maxWidth: 80, maxHeight: 40 }}
              />
            ) : null,
        },
      ] satisfies AColumn<ZBomColorRow>[],
    [],
  );
  return (
    <TableFacade
      store={tableStore}
      columns={columns}
      onRowClick={(row) => {
        selectOnRowClick(row, tableStore);
      }}
    />
  );
});
