import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import { TableStore } from "src/components/tables/TableStore";
import { ZEntityFilters } from "src/pages/EntityFiltersPage/EntityList/types";
import { FnFocus } from "src/components/Sheet/CellBox";
import { t } from "i18next";
import { MChartEntityStore } from "../../MChartEntityStore";
import styles from "./PointSelectExt.module.less";
import { PointOpt } from "../MChartTable/PointDict";
import { PointSelectModal } from "../PointSelectModal";

interface PropsPointSelectExt {
  store: MChartEntityStore;
  value?: PointOpt;
  onChange: (newValue?: PointOpt) => void;
  getFocus: (fn: FnFocus) => void;
}

export const PointSelectExt: React.FC<PropsPointSelectExt> = (props) => {
  const { value, onChange, store, getFocus } = props;
  const [tableStore, setTableStore] = React.useState<TableStore<
    PointOpt,
    ZEntityFilters
  > | null>(null);
  const close = () => setTableStore(null);
  const open = () => setTableStore(store.createPointSelectStore("radio"));
  const ref = React.useRef<HTMLButtonElement>(null);
  React.useEffect(() => {
    if (tableStore) return () => getFocus(undefined);
    getFocus(() => {
      ref.current?.focus();
    });
    return () => getFocus(undefined);
  }, [ref.current, tableStore]);
  return (
    <>
      <button
        className={styles.controlBox}
        onClick={open}
        type="button"
        ref={ref}
      >
        <div className={styles.controlValue}>{value?.label}</div>
        <DownOutlined />
      </button>
      <PointSelectModal
        title={t("Selecting a point")}
        tableStore={tableStore}
        close={close}
        success={(rows) => onChange(rows[0])}
        categoryList={store.categoryList}
      />
    </>
  );
};

PointSelectExt.defaultProps = {
  value: undefined,
};
