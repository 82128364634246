import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  ZSchedSettingsTask,
  ZSchedulingSettings,
} from "../../../SchedulingTypes";
import { DrawSection } from "./DrawSection";
import { SchedulingObjType } from "../../SchedulingSettingsStore";

interface PropsTaskRequiredSection {
  tabKey: keyof ZSchedulingSettings;
  objType: SchedulingObjType;
}

export const TaskRequiredSection: React.FC<PropsTaskRequiredSection> = ({
  tabKey,
  objType,
}) => (
  <DrawSection<ZSchedSettingsTask>
    tabKey={tabKey}
    objKey="templateObjectId"
    objType={objType}
    objLabel="Объект для формирования шаблона задачи"
    title="Список обязательных позиций"
    attrs={[
      {
        attrKey: "nameAttributeId",
        attrLabel: "Укажите атрибут названия задачи",
        attrType: AttrTypeName.dictSingle,
      },
      {
        attrKey: "durationAttributeId",
        attrLabel: "Укажите атрибут длительности задачи",
        attrType: AttrTypeName.int,
      },
      {
        attrKey: "groupAttributeId",
        attrLabel: "Укажите атрибут принадлежности задачи к группе задач",
        attrType: AttrTypeName.object,
      },
    ]}
  />
);
