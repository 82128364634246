import { observer } from "mobx-react-lite";
import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { t } from "i18next";
import styles from "./SchedulingHeader.module.less";
import { SchedulingControlStore } from "../SchedulingControlStore";
import { SchedTemplateTools } from "./SchedTemplateTools";

interface PropsSchedulingHeader {
  store: SchedulingControlStore;
}

export const SchedulingHeader: React.FC<PropsSchedulingHeader> = observer(
  ({ store }) => {
    const { projectInfo, data, settings } = store;
    const taskObjectId = settings?.taskSettings?.templateObjectId;
    return (
      <div className={styles.header}>
        <div className={styles.title}>{projectInfo?.name ?? ""}</div>
        <SchedTemplateTools store={store} />
        {taskObjectId && (
          <Button
            disabled={data.status !== "ready"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => store.openCreation(taskObjectId)}
          >
            {t("Create a task")}
          </Button>
        )}
      </div>
    );
  },
);
