import React from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { SchedulingControlStore } from "../../SchedulingControlStore";
import { SchedGroupCreator } from "./SchedGroupCreator";

interface PropsSchedTemplateTools {
  store: SchedulingControlStore;
}

export const SchedTemplateTools: React.FC<PropsSchedTemplateTools> = ({
  store,
}) => {
  const menuProps: MenuProps = {
    items: [
      {
        key: "taskGroup",
        label: "Группу задач",
        onClick: () => store.setOpenGroupCreate(true),
      },
    ],
  };
  return (
    <>
      <Dropdown menu={menuProps} placement="bottomRight">
        <Button>
          <Space>
            Создать из шаблона
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <SchedGroupCreator store={store} />
    </>
  );
};
