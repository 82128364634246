import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import {
  combineChecked,
  groupChecked,
  GroupChecked,
} from "src/common/groupChecked";
import { ZIdName } from "src/types/ZIdName";
import { SheetStore } from "src/components/Sheet/SheetStore";
import { ZBom2SummaryRow } from "../../Bom2Types";
import { EdBomPos, EdBomRow } from "./ZBom2Position";
import {
  createBom2Position,
  createEmptyBom2Position,
  deleteBom2Positions,
  loadBom2Positions,
} from "./apiBom2Position";
import { Bom2Column } from "./Bom2Table/Bom2Column";
import { buildBom2Columns } from "./Bom2Table/buildBom2Columns";
import { ZBom2Material } from "./ZBom2Material";
import { loadBom2ColorColumns } from "../../apiBom2";

export type Bom2PositionsBuzy = "delete" | false;

export class Bom2PositionStore {
  constructor(public readonly bom: ZBom2SummaryRow) {
    makeAutoObservable(this);
    this.init();
  }

  sheetStore = new SheetStore();

  positions: EdBomPos[] = [];

  setPositions(list: EdBomPos[]) {
    this.positions = list;
  }

  colorColumns: ZIdName[] = [];

  setColorColumns(list: ZIdName[]) {
    this.colorColumns = list;
  }

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  buzy: Bom2PositionsBuzy | false = false;

  setBuzy(type: Bom2PositionsBuzy) {
    this.buzy = type;
  }

  async init() {
    try {
      this.setLoading(true);
      this.clearSelection();
      this.clearCollapsed();
      this.setPositions([]);
      await this.reload();
    } catch (e) {
      onError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async reload() {
    try {
      this.setLoading(true);
      this.setPositions(await loadBom2Positions(this.bom.id));
      this.setColorColumns(await loadBom2ColorColumns(this.bom.id));
    } catch (e) {
      onError(e);
    } finally {
      this.setLoading(false);
    }
  }

  collapsed = new Set<number>();

  clearCollapsed() {
    this.collapsed.clear();
  }

  toggleCollapse({ materialTypeId }: EdBomPos) {
    const { collapsed } = this;
    if (collapsed.has(materialTypeId)) {
      collapsed.delete(materialTypeId);
    } else {
      collapsed.add(materialTypeId);
    }
  }

  selected = new Set<number>();

  clearSelection() {
    this.selected.clear();
  }

  get selectedPos() {
    const { selected, positions } = this;
    return positions.reduce(
      (acc, pos) => {
        const checked = groupChecked(pos.rows, ({ id }) => selected.has(id));
        return { ...acc, [pos.materialTypeId]: checked };
      },
      {} as Record<number, GroupChecked | undefined>,
    );
  }

  selectRow({ id }: EdBomRow, checked: boolean) {
    const { selected } = this;
    if (checked) {
      selected.add(id);
    } else {
      selected.delete(id);
    }
  }

  selectPos(pos: EdBomPos, checked: boolean) {
    pos.rows.forEach((row) => this.selectRow(row, checked));
  }

  get selectedAll(): GroupChecked | undefined {
    return (Object.values(this.selectedPos) || []).reduce(
      (acc, value) => combineChecked(acc, value),
      undefined,
    );
  }

  selectAll(checked: boolean) {
    if (!checked) {
      this.selected.clear();
    } else {
      const ids = this.positions
        .flatMap(({ rows }) => rows)
        .map(({ id }) => id);
      this.selected = new Set(ids);
    }
  }

  get columns(): Bom2Column[] {
    return buildBom2Columns(this);
  }

  get columnWidths(): string[] {
    return this.columns.map(({ minWidth, width }) => width ?? `${minWidth}px`);
  }

  // Создание позиций из материалов, которые выбраны в materialSelectStore
  async createPositions(selected: ZBom2Material[]) {
    await createBom2Position(
      this.bom.id,
      selected?.map(({ id }) => id),
    );
    this.reload().catch(onError);
  }

  async createEmptyPosition(materialTypeId: number) {
    await createEmptyBom2Position(this.bom.id, materialTypeId);
    this.reload().catch(onError);
  }

  get canDelete(): boolean {
    return this.selected.size > 0 && !this.buzy;
  }

  async doDelete() {
    try {
      const ids = Array.from(this.selected);
      if (ids.length > 0) {
        this.setBuzy("delete");
        await deleteBom2Positions(ids);
        this.clearSelection();
        this.reload().catch(onError);
      }
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  }
}
