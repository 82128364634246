import { observer } from "mobx-react-lite";
import React from "react";
import { Drawer, Space, Button } from "antd";
import { t } from "i18next";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { SchedulingControlStore } from "../../../SchedulingControlStore";
import styles from "./SchedGroupCreator.module.less";

interface PropsSchedGroupCreator {
  store: SchedulingControlStore;
}

export const SchedGroupCreator: React.FC<PropsSchedGroupCreator> = observer(
  ({ store }) => {
    const { openGroupCreate, groupCreateStore, operationLoading } = store;
    if (!groupCreateStore) return null;
    const handleClose = () => {
      store.setOpenGroupCreate(false);
      groupCreateStore.tableStore?.setSelected([]);
    };
    const disabled = !groupCreateStore.tableStore?.selected.length;
    return (
      <Drawer
        width="75vw"
        open={openGroupCreate}
        onClose={handleClose}
        className={styles.drawer}
        title={t("Task group template")}
        footer={
          <Space>
            <Button
              type="primary"
              loading={operationLoading}
              disabled={disabled}
              onClick={() => store.onGroupCreate()}
            >
              {t("Save")}
            </Button>
            <Button onClick={handleClose}>{t("Cancel")}</Button>
          </Space>
        }
      >
        <EntityFiltersTable store={groupCreateStore} />
      </Drawer>
    );
  },
);
