import { rest } from "src/common/rest";
import { apiMeasurementChart, apiObjUrl } from "src/common/apiUrl";
import { zEntity, ZEntity } from "src/types/ZEntity";
import { z } from "zod";
import { zIdName } from "src/types/ZIdName";
import {
  NewMeasurementChartEntityData,
  zMCPoint,
  ZMCPoint,
  zMCPointValue,
  ZMCPointValue,
  ZMCRow,
  zMeasurementChart,
  ZMeasurementChart,
} from "./ZMeasurementChart";
import { ZMesChartSettings, zMesChartSettings } from "./ZMesChartSettings";
import { NumOption } from "./MChartEntity/EditMCEntity/MChartTable/PointDict";

export const loadBaseSizes = async (
  servObjId: number,
  sizeId: number,
): Promise<NumOption[]> => {
  const resp = await rest.get(
    apiMeasurementChart(`/copy/${servObjId}/${sizeId}`),
  );
  const list = zIdName.array().parse(resp.data);
  return list.map(({ id, name }) => ({ value: id, label: name }));
};

export const loadEntitiesAsRef = async (
  objectId: number,
): Promise<ZEntity[]> => {
  const resp = await rest.post(
    apiObjUrl(`/entities/search`),
    { objectId },
    { params: { page: 0, size: 1000 } },
  );
  const zResp = z.object({
    content: zEntity.array(),
  });
  return zResp.parse(resp.data).content;
};

export const loadMesChartSettings = async (servObjId: number) => {
  const resp = await rest.get(apiMeasurementChart(`/settings/${servObjId}`));
  return zMesChartSettings.parse(resp.data);
};

export const saveMesChartSettings = async (
  servObjId: number,
  data: ZMesChartSettings,
) => {
  await rest.put(apiMeasurementChart(`/settings/${servObjId}`), data);
};

export const loadMeasurementChart = async (
  modelId: number,
): Promise<ZMeasurementChart> => {
  const resp = await rest.get(apiMeasurementChart(`/entity/${modelId}`));
  return zMeasurementChart.parse(resp.data);
};

export const scaleMeasurementChart = async (
  modelId: number,
  pointIds: number[],
): Promise<ZMeasurementChart> => {
  const resp = await rest.put(
    apiMeasurementChart(`/scale/${modelId}`),
    pointIds,
  );
  return zMeasurementChart.parse(resp.data);
};

export const createMeasurementChart = async (
  data: NewMeasurementChartEntityData,
): Promise<ZMeasurementChart> => {
  const resp = await rest.post(apiMeasurementChart("/entity"), data);
  return zMeasurementChart.parse(resp.data);
};

export const deleteMeasurementChart = async (
  modelId: number,
): Promise<void> => {
  await rest.delete(apiMeasurementChart(`/entity/${modelId}`));
};

export const copyMeasurementChart = async (
  fromId: number,
  toId: number,
): Promise<ZMeasurementChart> => {
  const url = apiMeasurementChart(`/copy/${fromId}/${toId}`);
  const resp = await rest.post(url);
  return zMeasurementChart.parse(resp.data);
};

export const saveMcPointValue = async (
  modelId: number,
  pointValue: ZMCPointValue,
): Promise<ZMCPointValue> => {
  const resp = await rest.put(
    apiMeasurementChart(`/point-value/${modelId}`),
    pointValue,
  );
  return zMCPointValue.parse(resp.data);
};

export const saveMcPontField = async <Field extends keyof ZMCPoint>(
  modelId: number,
  field: Field,
  point: ZMCPoint,
): Promise<ZMCPoint> => {
  const resp = await rest.put(
    apiMeasurementChart(`/mc-pont/${modelId}`),
    point,
    {
      params: {
        target: field,
      },
    },
  );
  return zMCPoint.parse(resp.data);
};

// POST /api/row/{entityId} Создание новой строки
export type NewMcRowData = {
  pointDto: {
    id: number;
    name: string;
  };
  description: string;
};
export const createNewMcRows = async (
  modelId: number,
  rows: NewMcRowData[],
): Promise<void> => {
  await rest.post(apiMeasurementChart(`/rows/${modelId}`), rows);
};

// DELETE /api/row/{entityId} Удаление строки
export const deleteMcRows = async (
  modelId: number,
  rows: ZMCRow[],
): Promise<void> => {
  const data: number[] = rows.map(({ mcPoint }) => mcPoint.id);
  await rest.delete(apiMeasurementChart(`/row/${modelId}`), { data });
};

export const addMcVersionToSize = async (
  modelId: number,
  sizeId: number,
  pointValue: Omit<ZMCPointValue, "id">,
) => {
  // POST /api/point-value/{entityId}/{sizeId}
  await rest.post(
    apiMeasurementChart(`/point-value/${modelId}/${sizeId}`),
    pointValue,
  );
};

export const deleteMcVersion = async (modelId: number, data: ZMCPointValue) => {
  await rest.delete(apiMeasurementChart(`/point-value/${modelId}`), { data });
};
