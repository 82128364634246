import { urlBom2 } from "src/businessServices/services/bsBom2/apiBom2";
import { rest } from "src/common/rest";
import { z } from "zod";

export const zBomColorRow = z.object({
  id: z.number(),
  name: z.string().nullable(),
  article: z.string().nullable(),
  image: z.string().nullable(),
});

export type ZBomColorRow = z.infer<typeof zBomColorRow>;

export type FilterBomColors = {};

const zBomColorsResponse = z.object({
  content: zBomColorRow.array(),
  totalElements: z.number(),
});

export const loadColors = async (page: number, size: number) => {
  const resp = await rest.get(urlBom2("/color/get-paginated"), {
    params: { page, size },
  });
  return zBomColorsResponse.parse(resp.data);
};
