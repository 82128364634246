// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__hideCheckbox--CR3mt {
  display: none;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__projectName--RnfAx,
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__projectStartDate--REbCY {
  font-weight: 700;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__groupName--ISf4f {
  font-weight: 600;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__taskRow--km80F {
  cursor: pointer;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__box--xZQ89 {
  height: 100%;
  overflow: hidden;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__tableContainer--dbrvH {
  flex: 1;
  overflow: hidden;
  height: 100%;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__cellEllipsis--U5Kds {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__cellSize--UUtZk {
  min-width: 100px;
  padding: 12px !important;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__cellSize--UUtZk:has(.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__titleContainer--iLWDw) {
  padding: 0 !important;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__cellName--QNHuy {
  border-right: 1px solid #F0F0F0;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__cellName--QNHuy::before {
  display: none;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__titleContent--K_ONm {
  user-select: none;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__titleContainer--iLWDw {
  position: relative;
  padding: 12px;
  height: 55px;
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsScheduling/SchedulingControl/SchedulingTable/SchedulingTable.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;;EACE,gBAAA;AACF;AAEA;EACE,gBAAA;AAAF;AAGA;EACE,eAAA;AADF;AAIA;EACE,YAAA;EACA,gBAAA;AAFF;AAKA;EACE,OAAA;EACA,gBAAA;EACA,YAAA;AAHF;AAMA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAJF;AAOA;EACE,gBAAA;EACA,wBAAA;AALF;AAQA;EACE,qBAAA;AANF;AASA;EACE,+BAAA;AAPF;AASE;EACE,aAAA;AAPJ;AAWA;EACE,iBAAA;AATF;AAYA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAVF","sourcesContent":[".hideCheckbox {\n  display: none;\n}\n\n.projectName, .projectStartDate {\n  font-weight: 700;\n}\n\n.groupName {\n  font-weight: 600;\n}\n\n.taskRow {\n  cursor: pointer;\n}\n\n.box {\n  height: 100%;\n  overflow: hidden;\n}\n\n.tableContainer {\n  flex: 1;\n  overflow: hidden; \n  height: 100%;\n}\n\n.cellEllipsis {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; \n}\n\n.cellSize {\n  min-width: 100px;\n  padding: 12px !important;\n}\n\n.cellSize:has(.titleContainer) {\n  padding: 0 !important;\n}\n\n.cellName {\n  border-right: 1px solid #F0F0F0;\n\n  &::before {\n    display: none;\n  }\n}\n\n.titleContent {\n  user-select: none;\n}\n\n.titleContainer {\n  position: relative;\n  padding: 12px;\n  height: 55px;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hideCheckbox": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__hideCheckbox--CR3mt`,
	"projectName": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__projectName--RnfAx`,
	"projectStartDate": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__projectStartDate--REbCY`,
	"groupName": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__groupName--ISf4f`,
	"taskRow": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__taskRow--km80F`,
	"box": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__box--xZQ89`,
	"tableContainer": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__tableContainer--dbrvH`,
	"cellEllipsis": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__cellEllipsis--U5Kds`,
	"cellSize": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__cellSize--UUtZk`,
	"titleContainer": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__titleContainer--iLWDw`,
	"cellName": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__cellName--QNHuy`,
	"titleContent": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingTable-SchedulingTable-module__titleContent--K_ONm`
};
export default ___CSS_LOADER_EXPORT___;
